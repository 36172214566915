import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SingclassImage = ({props}) => {
    const data = useStaticQuery(graphql`
    query {
        placeholderImage: file(relativePath: { eq: "singclass.jpg" }) {
            childImageSharp {
                fixed(width: 100, height: 55) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`)

  return <Img fixed={data.placeholderImage.childImageSharp.fixed} {...props}/>
}

export default SingclassImage