import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import SingclassImage from "../../components/logicom-images/Singclass.js"
import Table from 'react-bootstrap/Table'

const AgreeTest = () => (
    <Layout>
      <SEO title="Professional Services" />
      <Container>
        <Row><Col><h2>Professional Services</h2></Col></Row>
        <Row><Col>
            <p>Logicom is proud to be the sole agent for Thermotron brand of environmental test equipment and systems, vibration test systems, and
              functional test systems.</p>
            <Table>
              <ul>
                <li>Consultation</li>
                <li>Retrofit and overhaul of used test chamber and/or equipment</li>
                <li>Preventive maintenance and calibration of test equipment</li>
                <li>Sale of Thermotron brand of Environmental test chamber(s) and equipment</li>
              </ul>
            </Table>
            <p>Logicom is an accredited calibration lab under SAC-SINGLAS certification to ISO/IEC17025 Quality Standard.</p>
            <SingclassImage/>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
  
  export default AgreeTest
